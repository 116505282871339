type Props = {
    icon: JSX.Element
    tooltip: string
}

/**
 * This is a disabled action button and is used when the action isn't available, e.g. content has already been deleted.
 */
export default function ActionDisabled(props: Props) {
    const { icon, tooltip } = props
    return (
        <div
            className="h-10 w-10 cursor-not-allowed"
            data-tooltip-content={tooltip}
            data-tooltip-id="tooltip-id"
        >
            <div className={`btn-icon relative text-gray-400`}>{icon}</div>
        </div>
    )
}
