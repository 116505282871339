import { ReadFilterView } from '../../api/client'
import ArwenDetailedListBox, {
    ArwenDetailedListBoxItem,
} from '../inputs/ArwenDetailedListBox'

export enum ReadReceiptFilterOption {
    AnyStatus = 'AnyStatus',
}

type Props = {
    selection?: ReadFilterView
    onChange: (newSelection: ReadFilterView) => void
}

export default function ReadReceiptFilterComboBox({
    selection,
    onChange,
}: Props) {
    const options: ArwenDetailedListBoxItem[] = [
        {
            id: ReadFilterView.All,
            title: 'Any read status',
            description: 'Show any read status',
            disabled: false,
        },
        {
            id: ReadFilterView.UnreadByMe,
            title: 'Unread by me',
            description: "Show comments I've not read",
            disabled: false,
        },
        {
            id: ReadFilterView.UnreadByEveryone,
            title: 'Unread by anyone',
            description: "Show comments that haven't been read by anyone",
            disabled: false,
        },
        {
            id: ReadFilterView.ReadByMe,
            title: 'Read by me',
            description: 'Show comments I have read',
            disabled: false,
        },
    ]

    // Select the first item in the list, in this case - all statuses
    const value: ArwenDetailedListBoxItem =
        options.find((option) => option.id === `${selection}`) || options[0]

    return (
        <>
            <div
                className=" text-gray-950 flex"
                data-tooltip-id="tooltip-id"
                data-tooltip-content="Filter the results by read/unread status"
            >
                <ArwenDetailedListBox
                    onSelect={(option: ArwenDetailedListBoxItem) => {
                        const id = option.id as ReadFilterView
                        onChange(id)
                    }}
                    options={options}
                    selected={value}
                    widthClass="w-64"
                    label={'Filter the results by read/unread status'}
                />
            </div>
        </>
    )
}
