import { EnvelopeIcon, EnvelopeOpenIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { useRecoilState } from 'recoil'
import { ContentActionView, ContentOutcomeView } from '../../api/client'
import { userState } from '../../store/DomainStore'
import BaseActionButton from '../actions/buttons/BaseActionButton'
import LikeButton from '../actions/buttons/LikeButton'

type Props = {
    selectedContent: Map<number, ContentOutcomeView>
    onActionClick: (contentActions: ContentActionView[]) => void
    onClearSelectedContent: () => void
    onSelectAll: () => void
    onReadAll: () => void
    onUnreadAll: () => void
    showMarkAsReadLoadingIndicator: boolean
    showMarkAsUnreadLoadingIndicator: boolean
}

/**
 * Displays actions for the selected content.
 */
export default function SelectedContentBar(props: Props) {
    const {
        selectedContent,
        onClearSelectedContent,
        onActionClick,
        onSelectAll,
        onReadAll,
        onUnreadAll,
        showMarkAsReadLoadingIndicator,
        showMarkAsUnreadLoadingIndicator,
    } = props

    const [selectedAll, setSelectedAll] = useState(false)
    const [user] = useRecoilState(userState)

    function handleBulkSelectorClick() {
        if (selectedAll) {
            onClearSelectedContent()
        } else {
            onSelectAll()
        }
        setSelectedAll(!selectedAll)
    }

    return (
        <>
            <div className="h-12 text-sm  flex items-center bg-gray-100  px-4 py-2 justify-between">
                <>
                    <div className="flex flex-row items-center ">
                        <div>
                            <input
                                id="admin"
                                aria-describedby="admin-description"
                                type="checkbox"
                                checked={selectedAll}
                                className="h-4 w-4 rounded  border-gray-300 text-accent-2-500 focus:ring-accent-2-600 "
                                onChange={handleBulkSelectorClick}
                            />
                        </div>

                        <div className="ml-6 flex flex-row items-center gap-2 relative">
                            {user.canUseReadStatus && (
                                <>
                                    <div className="relative">
                                        <BaseActionButton
                                            tooltip={'Mark selected as read'}
                                            // loading={true}
                                            loading={
                                                showMarkAsReadLoadingIndicator
                                            }
                                            colour={'primary'}
                                            actionAlreadyApplied={false}
                                            icon={<EnvelopeOpenIcon />}
                                            onActionClick={onReadAll}
                                            isActionQueued={false}
                                            disabled={
                                                selectedContent.size === 0
                                            }
                                            disabledMessage={
                                                selectedContent.size === 0
                                                    ? 'Select comments to mark as read '
                                                    : ''
                                            }
                                        />
                                    </div>{' '}
                                    <div className="relative">
                                        <BaseActionButton
                                            tooltip={`Mark selected as unread`}
                                            loading={
                                                showMarkAsUnreadLoadingIndicator
                                            }
                                            colour={'primary'}
                                            actionAlreadyApplied={false}
                                            icon={<EnvelopeIcon />}
                                            onActionClick={onUnreadAll}
                                            isActionQueued={false}
                                            disabled={
                                                selectedContent.size === 0
                                            }
                                            disabledMessage={
                                                selectedContent.size === 0
                                                    ? 'Select comments to mark as unread'
                                                    : ''
                                            }
                                        />
                                    </div>
                                </>
                            )}
                            <div className="w-[1px] h-7 bg-gray-300"></div>
                            {user.canLikeContent && (
                                <LikeButton
                                    contents={Array.from(
                                        selectedContent.values()
                                    )}
                                    onActionClick={onActionClick}
                                    actions={[]}
                                    disabled={selectedContent.size === 0}
                                    disabledMessage={
                                        selectedContent.size === 0
                                            ? 'Select comments to like'
                                            : ''
                                    }
                                />
                            )}
                        </div>
                        <span className="text-gray-700 ml-2">
                            {selectedContent.size} selected
                        </span>
                        <div className="pl-4"></div>
                    </div>
                </>
            </div>
        </>
    )
}
