import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Field, Input, Label } from '@headlessui/react'
import { object, string } from 'yup'
import { useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'

import Button from '../../components/Button'
import {
    useCreateDashboardMutation,
    useGetEditableDashboardQuery,
    useUpdateDashboardMutation,
} from '../../api/client'
import { toastError, toastSuccess } from '../../components/Notification'
import { TextPageContainer } from '../../containers/TextPageContainer'
import { TextPageHeader } from '../../containers/TextPageHeader'
import { useEffect, useState } from 'react'
import UserSelectMenu from '../../components/user/UserSelectMenu'
import { ComboboxMenuOption } from '../../components/inputs/ComboboxMenuOption'
import SimpleLoader from '../../components/SimpleLoader'

interface FormData {
    extEmbeddedId: string
    description: string
    name: string
    selectedUsers?: { id: number; name: string }[]
}

const validationSchema = object({
    name: string().required('Name is required'),
    extEmbeddedId: string()
        .required('Dashboard ID is required')
        // Obviously this isn't real UUID validation. UUIDs come in different versions
        // and I'm not even certain the Preset dashboard ID is a UUID. So I'm just putting
        // something here to catch obviously wrong values.
        // .min(25, 'Dashboard ID must be a UUID'),
        .matches(
            /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/,
            'Must be a UUID'
        ),
    description: string().required('Description is required'),
})

export default function ReportFormPage() {
    const navigate = useNavigate()

    // EDIT OR VIEW?
    // We'll infer if we're in edit mode based on the presence of a dashboardId
    const { dashboardId: dashboardIdAsStr } =
        useParams<{ dashboardId?: string }>()
    // We've got to fall back on 0 if the dashboardId is invalid. That's because dashboardId can't be
    // undefined or null, and we can't call hooks conditionally. But not to worry, because this is only
    // used when we're in edit mode.
    const dashboardId = dashboardIdAsStr ? parseInt(dashboardIdAsStr, 10) : 0
    const isEditMode = Boolean(dashboardId)

    // VALIDATION
    const form = useForm<FormData>({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
    })

    // CREATE MUTATION
    const [createDashboardMutation, { loading: loadingCreate }] =
        useCreateDashboardMutation({
            onCompleted: (data) => {
                navigate(`/report/${data.createDashboard.id}`)
            },
            onError: (error) => {
                toastError(error.message)
            },
        })

    // EDIT - GET QUERY
    const { data, loading: loadingGetEditable } = useGetEditableDashboardQuery({
        fetchPolicy: 'network-only',
        variables: {
            dashboardId,
        },
        // Skip query if not in edit mode
        skip: !isEditMode,
        onCompleted: (data) => {
            const { users } = data.getEditableDashboard
            const selectedUsers = users.map((u) => ({
                id: u.id,
                name: u.username,
            }))
            setSelectedUsers(selectedUsers)
        },
    })

    // EDIT - UPDATE MUTATION
    const [updateDashboardMutation, { loading: updating }] =
        useUpdateDashboardMutation({
            onCompleted: (data) => {
                toastSuccess('Report updated')
                form.reset(form.getValues())
            },
            onError: (error) => {
                toastError(error.message)
            },
        })

    // EDIT - ADD USER QUERY
    const [selectedUsers, setSelectedUsers] = useState<ComboboxMenuOption[]>([])
    const handleSelectedUserChange = (selectedUsers: ComboboxMenuOption[]) => {
        setSelectedUsers(selectedUsers)
        form.setValue('selectedUsers', selectedUsers, { shouldDirty: true })
    }

    // EDIT - PRE-FILL FORM
    useEffect(() => {
        if (isEditMode && data?.getEditableDashboard) {
            const {
                displayName: name,
                description,
                extEmbeddedId,
            } = data.getEditableDashboard.dashboard
            form.reset({ name, description, extEmbeddedId })
        }
    }, [isEditMode, data, form])

    // EDIT AND VIEW - SUBMIT
    const onSubmit = async (data: FormData) => {
        if (isEditMode) {
            updateDashboardMutation({
                variables: {
                    dashboard: {
                        id: dashboardId,
                        displayName: data.name,
                        description: data.description,
                        extEmbeddedId: data.extEmbeddedId,
                        userIds: selectedUsers.map((u) => u.id),
                    },
                },
            })
        } else {
            createDashboardMutation({
                variables: {
                    dashboard: {
                        displayName: data.name,
                        description: data.description,
                        extEmbeddedId: data.extEmbeddedId,
                        userIds: selectedUsers.map((u) => u.id),
                    },
                },
            })
        }
    }

    // if (loadingGetEditable) return <SimpleLoader loading={loadingGetEditable} />
    return (
        <TextPageContainer>
            <TextPageHeader
                title={isEditMode ? 'Edit report' : 'Create a new report'}
            />
            {loadingGetEditable ? (
                <div className="w-full flex">
                    <SimpleLoader loading={loadingGetEditable} />
                </div>
            ) : (
                <FormProvider {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="divide-gray-200"
                    >
                        <Field className="mt-6">
                            <Label className="block font-medium leading-6 text-gray-900">
                                Name
                            </Label>
                            <Input
                                type="text"
                                className="w-full"
                                placeholder="A short name"
                                {...form.register('name')}
                            />
                            {form.formState.errors.name && (
                                <span className="text-yellow-600 text-sm">
                                    {form.formState.errors.name.message}
                                </span>
                            )}
                        </Field>

                        <Field className="mt-6">
                            <Label className="block font-medium leading-6 text-gray-900">
                                Description
                            </Label>
                            <Input
                                className="w-full"
                                placeholder="A longer description of the report and its purpose."
                                {...form.register('description')}
                            />
                            {form.formState.errors.description && (
                                <span className="text-yellow-600 text-sm">
                                    {form.formState.errors.description.message}
                                </span>
                            )}
                        </Field>

                        <Field className="mt-6">
                            <Label className="block font-medium leading-6 text-gray-900">
                                Dashboard ID (from the Preset dashboard
                                embedding settings)
                            </Label>
                            <Input
                                type="text"
                                className="w-full"
                                placeholder="em9f1b2a21-f948-46a5-a4bb-58ac3368a733"
                                {...form.register('extEmbeddedId')}
                            />
                            {form.formState.errors.extEmbeddedId && (
                                <span className="text-yellow-600 text-sm">
                                    {
                                        form.formState.errors.extEmbeddedId
                                            .message
                                    }
                                </span>
                            )}
                        </Field>
                        <UserSelectMenu
                            selectedUsers={selectedUsers}
                            setSelectedUsers={handleSelectedUserChange}
                        />

                        <div className="mt-10">
                            <Button
                                primary
                                text={
                                    isEditMode
                                        ? 'Save changes'
                                        : 'Create and view'
                                }
                                type="submit"
                                size="large"
                                disabled={
                                    loadingCreate ||
                                    updating ||
                                    !form.formState.isDirty
                                }
                            />
                        </div>
                    </form>
                </FormProvider>
            )}
        </TextPageContainer>
    )
}
