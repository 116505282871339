import clsx from 'clsx'
import {
    ContentActionView,
    ContentOutcomeView,
    EnrichedContent,
    ProfileView,
    TranslatedTextView,
    useCreateReadReceiptMutation,
    useDeleteReadReceiptMutation,
} from '../../../api/client'
import avatar from '../../../assets/avatar.jpeg'
import { faIconName } from '../../../util/classNames'
import ActionRow from '../../actions/ActionRow'
import { toastError, toastSuccess } from '../../Notification'
import { SearchParameters } from '../search/ContentSearchBar'
import ContentClassificationList from './ContentClassificationList'
import ContentLink from './ContentLink'
import ContentReplyTo from './ContentReplyTo'
import ContentSelector from './ContentSelector'
import ContentSidebar from './ContentSidebar'
import ContentText from './ContentText'
import ImageAbuseSummary from './ImageAbuseSummary'
import ContentMetadata from './metadata/ContentMetadata'
import ReplyText from './ReplyText'
import { useRecoilState } from 'recoil'
import { userState } from '../../../store/DomainStore'

// In the future, I think we should do an enum with actions.
const REPLY_ACTION = 'REPLY'

type Props = {
    content: ContentOutcomeView
    showAccount: Boolean
    onContentAction: (
        contentActions: ContentActionView[],
        queued?: boolean
    ) => void
    enriching: boolean
    translatedText?: TranslatedTextView
    translating: boolean
    profileCache: ProfileView[]
    enrichedContentMap: Map<number, EnrichedContent>
    actionCacheMap: Map<number, ContentActionView[]>
    isSelected: boolean
    onSelectionChange: (
        content: ContentOutcomeView,
        isSelected: boolean
    ) => void
    // this is a hack Zoe made me do it
    rounded?: boolean
    enableBulkActions?: boolean
    // Search parameters can be used to customise the content card based on what the user has searched for, e.g. changing the classification list or highlighting search text
    searchParameters?: SearchParameters
    score?: number
    onMarkAsReadClick: (content: ContentOutcomeView) => void
    onMarkAsUnreadClick: (content: ContentOutcomeView) => void
}

function ContentCard(props: Props) {
    const {
        content,
        showAccount,
        onContentAction,
        enriching,
        translatedText,
        translating,
        profileCache,
        enrichedContentMap,
        actionCacheMap,
        rounded,
        isSelected,
        onSelectionChange,
        enableBulkActions,
        searchParameters,
        score,
        onMarkAsReadClick,
        onMarkAsUnreadClick,
    } = props

    const contentActionClicked = async function (actions: ContentActionView[]) {
        onContentAction(actions)
    }

    // This is used to help manage the read receipts
    const [user] = useRecoilState(userState)

    /** ******************************************************
        READ RECEIPT
        */

    const [createReadReceiptMutation, { loading: createReadReceiptLoading }] =
        useCreateReadReceiptMutation({
            onError: (error) => {
                toastError(error.message)
            },
            onCompleted: (result) => {
                toastSuccess(`Marked as read`)
                content.readReceipts.push(result.createReadReceipt)
            },
        })

    const [deleteReadReceiptMutation, { loading: deleteReadReceiptLoading }] =
        useDeleteReadReceiptMutation({
            onError: (error) => {
                toastError(error.message)
            },
            onCompleted: (result) => {
                content.readReceipts = content.readReceipts.filter(
                    (receipt) => receipt.userId !== user.id
                )
                toastSuccess(`Marked as unread`)
            },
        })

    let moderationStyle = content.moderation?.toLowerCase()
    let iconName = faIconName(
        content.socialMediaServiceName.toLowerCase(),
        false
    )

    // The key here is the serviceAuthorId - we convert to a map as it is a faster lookup.
    const profileMap = new Map<number, ProfileView>(
        profileCache.map((profile) => [profile.id, profile])
    )
    // Extract the profile from the profileMap
    const profilePath =
        profileMap?.get(content.authorProfileId || -1)?.profileImagePath ||
        undefined

    // Extract the profile from the profileMap
    const enrichedContent = enrichedContentMap.get(content.id)

    const cardClasses = `${isSelected ? 'bg-primary-100' : ''}`

    const reply = content.actions.find(
        (action) => action.action === REPLY_ACTION
    )

    const handleMarkAsReadClick = async function (content: ContentOutcomeView) {
        await createReadReceiptMutation({
            variables: { contentId: content.id },
        })

        onMarkAsReadClick(content)
    }

    const handleMarkAsUnreadClick = async function (
        content: ContentOutcomeView
    ) {
        await deleteReadReceiptMutation({
            variables: { contentId: content.id },
        })

        onMarkAsUnreadClick(content)
    }

    return (
        <div key={content.id} className={`group ${cardClasses}`}>
            <div className="flex flex-row relative">
                {enableBulkActions && (
                    <ContentSelector
                        content={content}
                        isSelected={isSelected}
                        onSelectionChange={onSelectionChange}
                    />
                )}
                <ContentSidebar
                    iconName={iconName}
                    moderationStyle={moderationStyle}
                    isPublic={content.isPublic}
                    socialMediaServiceName={content.socialMediaServiceName}
                    rounded={rounded}
                    isSelected={isSelected}
                />
                {content.account.profileCollectionEnabled && (
                    <div className="ml-2 mt-2 flex shrink-0">
                        <img
                            className="h-14 w-14 min-w-14 min-h-14 rounded-full border-slate-300 border shadow-md"
                            src={profilePath || avatar}
                            onError={(event) => {
                                //@ts-ignore
                                event.target.onerror = null
                                //@ts-ignore
                                event.target.src = avatar
                            }}
                            alt="avatar"
                        />
                    </div>
                )}
                <div
                    className={clsx(
                        'flex flex-grow flex-col justify-between pb-2 pl-4 pr-4 pt-3 '
                    )}
                >
                    <div>
                        <div className="mb-1 flex flex-row justify-between">
                            <ContentMetadata
                                content={content}
                                enrichedContent={enrichedContent}
                            />

                            <div className="flex">
                                <div className="flex items-baseline">
                                    {score ? (
                                        <p className="pr-2 font-bold">
                                            {Math.round(score * 100)}%
                                        </p>
                                    ) : null}
                                    {showAccount && (
                                        <p className="pr-2 text-gray-700">
                                            <span className="">
                                                {content.account?.accountName}
                                            </span>
                                        </p>
                                    )}
                                    <ContentLink
                                        content={content}
                                        enrichedContent={enrichedContent}
                                        enriching={enriching}
                                    />
                                </div>
                            </div>

                            <ContentReplyTo content={content} />
                        </div>
                        <ContentText
                            content={content}
                            translatedText={translatedText}
                            translating={translating}
                        />
                    </div>
                    <div className="mt-1 text-gray-700">
                        <div className="flex flex-row flex-wrap items-end justify-between">
                            <div className="flex flex-col">
                                <div className="flex flex-row">
                                    <ContentClassificationList
                                        content={content}
                                        searchParameters={searchParameters}
                                    />
                                </div>
                                <div className="mt-1 flex flex-row">
                                    <ImageAbuseSummary media={content.media} />
                                </div>
                            </div>
                            <div className="flex flex-row items-center ">
                                {content.account && (
                                    <ActionRow
                                        content={content}
                                        onActionClick={contentActionClicked}
                                        profileCache={profileCache}
                                        actionCacheMap={actionCacheMap}
                                        onMarkAsReadClick={
                                            handleMarkAsReadClick
                                        }
                                        onMarkAsUnreadClick={
                                            handleMarkAsUnreadClick
                                        }
                                        readReceiptLoading={
                                            createReadReceiptLoading ||
                                            deleteReadReceiptLoading
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {reply?.message && (
                        <ReplyText
                            reply={reply.message}
                            content={content}
                            onActionClick={contentActionClicked}
                            profileCache={profileCache}
                            actionCacheMap={actionCacheMap}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default ContentCard
