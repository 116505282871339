import { Transition } from '@headlessui/react'
import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline'
import useQueryParams from '../hooks/useQueryParams'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/brand/arwen-logo-red-medium.png'
import Header from '../components/Header'
import SidebarNav from '../components/sidebar/SidebarNav'
import { stringToBoolean } from '../util/conversions'

type Props = {
    children: React.ReactNode
}

function Sidebar(props: Props) {
    // We're going to put the sidebar status into local storage so that it persists between page loads.
    const [sidebarOpen, setSidebarOpen] = useState(() => {
        const saved = localStorage.getItem('sidebarOpen')
        return saved === 'true'
    })
    useEffect(() => {
        localStorage.setItem('sidebarOpen', sidebarOpen.toString())
    }, [sidebarOpen])
    // Helpful
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen)
    }

    // When we're in the onboarding flow we want to hide the sidebar toggle.
    // The onboarding state can be reset by appending `&onboarding=false` to the URL.
    // But it'll also be reset in `AccountsPage.tsx`, which is the homepage.
    // I.e. going to the homepage is the no-more-onboarding-please escape hatch.
    const [onboarding, setOnboarding] = useState(() => {
        const saved = localStorage.getItem('onboarding')
        return saved === 'true'
    })
    useEffect(() => {
        localStorage.setItem('onboarding', onboarding.toString())
    }, [onboarding])
    const onboardingParam = stringToBoolean(useQueryParams().get('onboarding'))
    useEffect(() => {
        if (onboardingParam === null) {
            // If the onboarding param is null then we won't change what's in the local storage.
        } else {
            setOnboarding(onboardingParam)
        }
    }, [onboarding, onboardingParam])

    const sidebarOpenClasses =
        'transition-[width] fixed inset-y-0 flex w-60 flex-col overflow-x-hidden'
    const sidebarClosedClasses =
        'transition-[width] fixed inset-y-0 flex w-0 flex-col overflow-x-hidden'

    const sidebarClasses = sidebarOpen
        ? sidebarOpenClasses
        : sidebarClosedClasses

    const topbarOpenClasses =
        'transition-[padding] flex flex-1 flex-col pl-60 full-screen'
    const topbarClosedClasses =
        'transition-[padding] flex flex-1 flex-col pl-0 full-screen'

    const topbarClasses = sidebarOpen ? topbarOpenClasses : topbarClosedClasses

    return (
        <div className="full-screen">
            <div className={sidebarClasses}>
                <div className="flex flex-grow flex-col w-overflow-y-auto border-r border-gray-200 bg-white">
                    <div className="flex overflow-x-hidden w- pl-4 text-lg">
                        <Link to="/">
                            <div>
                                <Transition
                                    show={sidebarOpen}
                                    enter="transition-opacity duration-100"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition-opacity duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <img
                                        alt="ARWEN logo - click to go home"
                                        src={logo}
                                        className="pt-2"
                                        style={{
                                            minWidth: '200px',
                                            width: '200px',
                                            position: 'absolute',
                                        }}
                                    />
                                </Transition>
                            </div>
                        </Link>
                    </div>
                    <div className="mt-5 flex flex-grow flex-col absolute top-20">
                        <SidebarNav sidebarOpen={sidebarOpen} />
                    </div>
                    <div className="mt-5 flex flex-grow flex-col">{''}</div>
                </div>
            </div>

            <div className={topbarClasses}>
                <div
                    className="top-0 flex h-16 flex-shrink-0 bg-white shadow"
                    // Getting this correct was one of the least fun bits of dev in the past few months.
                    // The "full-screen" class applied in the Sidebar component sets all children to overflow: hidden.
                    // This breaks the dropdown display which needs overflow:visible applied aggressively. There is
                    // probably a better way to do this, but I stopped looking.
                    style={{ overflow: 'visible' }}
                >
                    {!onboarding ? (
                        <button
                            type="button"
                            className="border-r border-gray-200 px-4  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
                            onClick={() => toggleSidebar()}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <Bars3BottomLeftIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </button>
                    ) : null}

                    <Header showLogo={!sidebarOpen} />
                </div>

                {props.children}
            </div>
        </div>
    )
}

export default Sidebar
