export type PromptTestCase = {
    text: string
    target: 'Yes' | 'No'
}

export type PromptResult = {
    text: string
    target: 'Yes' | 'No'
    result: boolean
}

export function parseMessages(messages: string): PromptTestCase[] | undefined {
    try {
        const lines = messages.split('\n')
        const testCases: PromptTestCase[] = []
        for (const line of lines) {
            const trimmedLine = line.trim()

            if (trimmedLine) {
                const lineComponents = trimmedLine.split(',')
                if (lineComponents.length === 2) {
                    const text = lineComponents[0].trim()
                    const target = lineComponents[1].trim()
                    const textWithoutQuotes =
                        text.startsWith('"') && text.endsWith('"')
                            ? text.slice(1, -1)
                            : text

                    const targetWithoutQuotes =
                        target.startsWith('"') && target.endsWith('"')
                            ? target.slice(1, -1)
                            : target
                    if (
                        targetWithoutQuotes === 'Yes' ||
                        targetWithoutQuotes === 'No'
                    ) {
                        testCases.push({
                            text: textWithoutQuotes,
                            target: targetWithoutQuotes as 'Yes' | 'No',
                        })
                    }
                }
            }
        }
        return testCases
    } catch (error) {
        return undefined
    }
}

export function evaluateMetrics(testResults: PromptResult[]): {
    accuracy: number
    precision: number
    recall: number
} {
    let truePositive = 0
    let trueNegative = 0
    let falsePositive = 0
    let falseNegative = 0

    // Loop through test results and classify
    for (const entry of testResults) {
        const target = entry.target.toLowerCase()
        const result = entry.result

        if (target === 'yes' && result) {
            truePositive++
        } else if (target === 'no' && !result) {
            trueNegative++
        } else if (target === 'no' && result) {
            falsePositive++
        } else if (target === 'yes' && !result) {
            falseNegative++
        }
    }

    // Calculate accuracy
    const accuracy = (truePositive + trueNegative) / testResults.length

    // Calculate precision (handle division by zero)
    const precision =
        truePositive + falsePositive > 0
            ? truePositive / (truePositive + falsePositive)
            : 0

    // Calculate recall (handle division by zero)
    const recall =
        truePositive + falseNegative > 0
            ? truePositive / (truePositive + falseNegative)
            : 0

    return {
        accuracy: Math.round(accuracy * 100),
        precision: Math.round(precision * 100),
        recall: Math.round(recall * 100),
    }
}
