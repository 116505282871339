import { useAuthenticator } from '@aws-amplify/ui-react'
import { useQuery } from '@tanstack/react-query'
import { ReferrerData, fetchReferrerData } from './api/unauth_api/referrer'
import MainErrorBoundary from './components/errors/MainErrorBoundary'
import LoadingWelcome from './components/unauthenticated/LoadingWelcome'
import AppInitialisationContainer from './containers/AppInititialisationContainer'
import Main from './containers/Main'
import useQueryParams from './hooks/useQueryParams'
import { Referrer } from './models/Referrer'
import UnauthenticatedPage from './pages/UnauthenticatedPage'

/**
 * We use the Amplify UI Authenticator component to authenticate against Cognito.
 * See here on how to do further configuration
 * https://ui.docs.amplify.aws/components/authenticator#headers--footers
 *
 * Docs for useAuthenticator - will allow us to access auth state and functions throughout the app
 * https://ui.docs.amplify.aws/components/authenticator#useauthenticator-hook
 */

function App() {
    const { route } = useAuthenticator((context: any) => [context.route])
    const query = useQueryParams()

    const referrerUuid = query.get('referrer')

    const { data, isLoading } = useQuery<ReferrerData, Error>({
        queryKey: ['referrerData', referrerUuid],
        queryFn: () => {
            if (!referrerUuid) {
                return Promise.reject(new Error('Referrer UUID is undefined'))
            }
            return fetchReferrerData(referrerUuid)
        },
        retry: false,
        refetchOnWindowFocus: false,
        enabled: !!referrerUuid, // Enable the query only if referrerUuid is defined
    })

    const referrer: Referrer | undefined = data?.referrer
    const hasReachedUserLimit = data?.hasReachedUserLimit

    function generateAuthenticated() {
        return (
            <AppInitialisationContainer>
                <div className="relative full-screen">
                    <div className="full-screen">
                        <Main />
                    </div>
                </div>
            </AppInitialisationContainer>
        )
    }

    const generateLoading = () => {
        return <LoadingWelcome />
    }

    const generateContent = () => {
        if (isLoading) {
            return generateLoading()
        } else {
            if (route === 'authenticated') {
                return generateAuthenticated()
            } else {
                return (
                    <UnauthenticatedPage
                        referrer={referrer}
                        hasReachedUserLimit={hasReachedUserLimit}
                    />
                )
            }
        }
    }

    return <MainErrorBoundary>{generateContent()}</MainErrorBoundary>
}

export default App
