import { SocialMediaServiceEnum } from '../api/client'
import ArwenDetailedListBox, {
    ArwenDetailedListBoxItem,
} from './inputs/ArwenDetailedListBox'

export enum AdOrOrganicFilterOption {
    Ads = 'ADS',
    Organic = 'ORGANIC',
    Both = 'BOTH',
}

type Props = {
    selection?: AdOrOrganicFilterOption
    socialMediaService?: SocialMediaServiceEnum
    onChange: (newSelection: AdOrOrganicFilterOption) => void
}

export default function AdOrOrganicFilterComboBox({
    selection,
    onChange,
    socialMediaService,
}: Props) {
    // This chunk does two things:
    // 1. It disables certain options in the dropdown. E.g. if we're looking at a TikTok Ads profile
    //    there's no point letting the user select Organic, or both.
    // 2. Sets the default selection, if there is none. E.g. if we're looking at a TikTok Ads profile
    //    then there's on one available option, and that's Ads, so we'll select that as the default.
    let disabledOptions: AdOrOrganicFilterOption[] = []
    if (socialMediaService) {
        switch (socialMediaService) {
            case SocialMediaServiceEnum.Tiktokad:
                // Only supports Ads, so disable organic and both
                disabledOptions.push(AdOrOrganicFilterOption.Organic)
                disabledOptions.push(AdOrOrganicFilterOption.Both)
                if (!selection) {
                    selection = AdOrOrganicFilterOption.Ads
                }
                break
            case SocialMediaServiceEnum.Tiktokuser:
                // Only supports organic, so disable ads and both
                disabledOptions.push(AdOrOrganicFilterOption.Ads)
                disabledOptions.push(AdOrOrganicFilterOption.Both)
                if (!selection) {
                    selection = AdOrOrganicFilterOption.Organic
                }
                break
            case SocialMediaServiceEnum.Twitter:
                // Only supports organic, so disable ads and both
                disabledOptions.push(AdOrOrganicFilterOption.Ads)
                disabledOptions.push(AdOrOrganicFilterOption.Both)
                if (!selection) {
                    selection = AdOrOrganicFilterOption.Organic
                }
                break
            case SocialMediaServiceEnum.Youtube:
                // Only supports organic, so disable ads and both
                disabledOptions.push(AdOrOrganicFilterOption.Ads)
                disabledOptions.push(AdOrOrganicFilterOption.Both)
                if (!selection) {
                    selection = AdOrOrganicFilterOption.Organic
                }
                break
            default:
                if (!selection) {
                    selection = AdOrOrganicFilterOption.Both
                }
                /// No disable options for everything else
                break
        }
    } else {
        // The default selection if there's no account selected, i.e. for content search
        if (!selection) {
            selection = AdOrOrganicFilterOption.Both
        }
    }

    const options: ArwenDetailedListBoxItem[] = [
        {
            id: AdOrOrganicFilterOption.Both,
            title: 'Ads and organic',
            description:
                'Show comments on both ads and organic posts (the default)',
            disabled: disabledOptions.includes(AdOrOrganicFilterOption.Both),
        },
        {
            id: AdOrOrganicFilterOption.Ads,
            title: 'Ads',
            description: 'Show comments on ads',
            disabled: disabledOptions.includes(AdOrOrganicFilterOption.Ads),
        },
        {
            id: AdOrOrganicFilterOption.Organic,
            title: 'Organic',
            description: 'Show comments on organic posts',
            disabled: disabledOptions.includes(AdOrOrganicFilterOption.Organic),
        },
    ]

    const value: ArwenDetailedListBoxItem =
        options.find((option) => option.id === `${selection}`) || options[2]

    return (
        <div
            className=" text-gray-950 flex"
            data-tooltip-content="Filter the results by comment type"
            data-tooltip-id="tooltip-id"
        >
            <ArwenDetailedListBox
                onSelect={(option: ArwenDetailedListBoxItem) => {
                    const id = option.id as AdOrOrganicFilterOption
                    onChange(id)
                }}
                options={options}
                selected={value}
                widthClass="w-42"
                label="Filter the results by comment type"
            />
        </div>
    )
}
