import {
    ClassifierDetails,
    ContentClassificationView,
    ContentOutcomeView,
    ModerationBoundaryView,
} from '../api/client'
import { Queue, SeverityCategory } from '../models/Moderation'

const SAFE_CLASSIFICATION_NUMERICAL_THRESHOLD = 0.5
const SAFE_CLASSIFICATION_CATEGORICAL_THRESHOLD = SeverityCategory.LOW

// Some classifiers we always want to show. They may be irrelevant to moderation, e.g. never be in suspect or severe
// or they may just be interesting.
const INCLUSION_LIST = [
    'llm_01',
    'llm_02',
    'llm_03',
    'llm_04',
    'llm_05',
    'llm_06',
    'llm_07',
    'llm_08',
    'llm_09',
    'llm_10',
    'curiosity',
]
const SENTIMENT_NAME = 'sentiment'

export type ClassifiersToDisplay = {
    relevantSevereClassifications: ContentClassificationView[]
    relevantSuspectClassifications: ContentClassificationView[]
    safeClassifications: ContentClassificationView[]
    alwaysShowClassifications: ContentClassificationView[]
    engageClassifications: ContentClassificationView[]
}

export function getClassifiersToDisplay(
    content: ContentOutcomeView,
    configuredBoundaries: ModerationBoundaryView[],
    classifiersMap: Map<string, ClassifierDetails>,
    isEngagePage: boolean,
    searchParameters: any,
    engageClassifiersList: string[]
): ClassifiersToDisplay {
    const classifiersToDisplay: ClassifiersToDisplay = {
        relevantSevereClassifications: [],
        relevantSuspectClassifications: [],
        safeClassifications: [],
        alwaysShowClassifications: [],
        engageClassifications: [],
    }

    // Get all relevant severe classifications
    classifiersToDisplay.relevantSevereClassifications =
        relevantClassificationsForQueue(
            'SEVERE',
            content.allClassifications,
            configuredBoundaries,
            classifiersMap
        )

    // Let's continue to find the suspect ones...
    // BUT... first we need to remove the severe ones from the list of all classifications
    const nonSevereClassifications = content.allClassifications.filter(
        (classification) => {
            return !!!classifiersToDisplay.relevantSevereClassifications.find(
                (severeClassification) =>
                    classification.classifierName ===
                    severeClassification.classifierName
            )
        }
    )
    classifiersToDisplay.relevantSuspectClassifications =
        relevantClassificationsForQueue(
            'SUSPECT',
            nonSevereClassifications,
            configuredBoundaries,
            classifiersMap
        )

    // There are some classifications that we always want to show, these are defined by the inclusion list.
    classifiersToDisplay.alwaysShowClassifications = content.allClassifications
        .filter((classification) => {
            return (
                !!!classifiersToDisplay.relevantSevereClassifications.find(
                    (severeClassification) =>
                        classification.classifierName ===
                        severeClassification.classifierName
                ) &&
                !!!classifiersToDisplay.relevantSuspectClassifications.find(
                    (suspectClassification) =>
                        classification.classifierName ===
                        suspectClassification.classifierName
                ) &&
                INCLUSION_LIST.includes(classification.classifierName)
            )
        })
        // And ignore the ones that are really low significance
        .filter(isHighEnoughScore)

    // The rest of the classifications are not displayed unless the user is an admin and then a chevron lets them see it.
    classifiersToDisplay.safeClassifications = content.allClassifications
        .filter((classification) => {
            return (
                !!!classifiersToDisplay.relevantSevereClassifications.find(
                    (severeClassification) =>
                        classification.classifierName ===
                        severeClassification.classifierName
                ) &&
                !!!classifiersToDisplay.relevantSuspectClassifications.find(
                    (suspectClassification) =>
                        classification.classifierName ===
                        suspectClassification.classifierName
                ) &&
                !!!classifiersToDisplay.alwaysShowClassifications.find(
                    (suspectClassification) =>
                        classification.classifierName ===
                        suspectClassification.classifierName
                )
            )
        })
        // And ignore the ones that are really low significance
        .filter(isHighEnoughScore)

    return classifiersToDisplay
}

/**
 * Convert the categorical severity to a percentage.
 *
 * This is useful for comparing severities to see if one is greater
 * than another and so used for determining if a severity meets a boundary.
 *
 * It is also useful for generating the radial progress display.
 *
 * So PLEASE CONSIDER the radial progress display if you decide to change
 * these values
 *
 * */
export function convertSeverityToPercentage(severity: string): number {
    switch (severity) {
        case SeverityCategory.NONE:
            return 0
        case SeverityCategory.NOTDETECTED:
            return 0
        case SeverityCategory.LOW:
            return 0.25
        case SeverityCategory.MEDIUM:
            return 0.5
        case SeverityCategory.HIGH:
            return 0.75
        case SeverityCategory.EXTREME:
            return 1
        default:
            return 0
    }
}

/**
 * A classification is "uninteresting" if its score is below 0.5.
 *
 * It basically means unable to determine anything or, that the probability is quite low.
 */
function isHighEnoughScore(classification: ContentClassificationView): boolean {
    const doesReachProbabilityScoreSignificance: boolean = !!(
        classification.classification &&
        classification.classification > SAFE_CLASSIFICATION_NUMERICAL_THRESHOLD
    )

    const doesReactCategoricalScoreSignificance: boolean = !!(
        classification.severity &&
        convertSeverityToPercentage(classification.severity) >=
            convertSeverityToPercentage(
                SAFE_CLASSIFICATION_CATEGORICAL_THRESHOLD
            )
    )

    return (
        doesReachProbabilityScoreSignificance ||
        doesReactCategoricalScoreSignificance
    )
}

/**
 * A classifier is "relevant" for a queue when it included as a moderation
 * boundary and the score breaches that boundary.
 */
function relevantClassificationsForQueue(
    queue: Queue,
    classifications: ContentClassificationView[],
    configuredBoundaries: ModerationBoundaryView[],
    classifiersMap: Map<string, ClassifierDetails>
): ContentClassificationView[] {
    // First identify all the boundaries that are relevant to this queue
    // and put them in a map for speedier lookup
    const configuredBoundariesMap = new Map<number, ModerationBoundaryView>(
        configuredBoundaries
            .filter((boundary) => boundary.queue === queue)
            .map((boundary) => {
                return [boundary.classifier.id || 0, boundary]
            })
    )

    return classifications.filter((classification) => {
        // First find the classifier that matches the classification. We do this by name
        const matchingClassifier = classifiersMap.get(
            classification.classifierName.toLowerCase()
        )

        // Is there a boundary configured against this classifier?
        const matchingBoundary = configuredBoundariesMap.get(
            matchingClassifier?.id || 0
        )

        // Check the numerical probability
        if (classification.classification && matchingBoundary) {
            if (classification.classifierName === SENTIMENT_NAME) {
                if (
                    matchingBoundary?.probability &&
                    classification.classification <=
                        matchingBoundary.probability
                ) {
                    return true
                }
            } else {
                // Lets see if the classification of this item is greater than the boundary
                if (
                    matchingBoundary?.probability &&
                    classification.classification >=
                        matchingBoundary.probability
                ) {
                    return true
                }
            }
        }

        // Check against the categorical classification
        if (matchingBoundary?.severityCategory && classification.severity) {
            if (
                convertSeverityToPercentage(classification.severity) >=
                convertSeverityToPercentage(matchingBoundary.severityCategory)
            ) {
                return true
            }
        }

        // No matching bondary found.
        return false
    })
}
