import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import {
    ClassifierDetails,
    ContentOutcomeView,
    ModerationBoundaryView,
    useGetClassifierDetailsQuery,
    useGetModerationBoundariesQuery,
} from '../../../api/client'
import { EMOTIONS_LIST } from '../../../pages/IntelligencePage'
import { isUserAdminState } from '../../../store/DomainStore'
import {
    ClassifiersToDisplay,
    getClassifiersToDisplay,
} from '../../../util/classifierListUtils'
import { SearchParameters } from '../search/ContentSearchBar'
import ClassificationDisplay from './ClassificationDisplay'
import ShowHideChevronToggle from './ShowHideChevronToggle'

type Props = {
    content: ContentOutcomeView
    searchParameters?: SearchParameters
}

/**
 * Shows a list of the content classifications.
 *
 * By default this only shows classifications that have contributed to the
 * current moderation. So it shows only the classifications that are relevant
 * to the moderation.
 *
 * If the user is an admin it will also show a "Show All" button to show
 * all the other classifications that are above a certain figure (0.2/LOW I think)
 */
export default function ContentClassificationList(props: Props) {
    const { content, searchParameters } = props
    const [showAll, setShowAll] = useState(false)

    //FIXME:  We should be making more use of routes to configure components directly rather than passing props like searchParameters
    const location = useLocation()
    const isEngagePage: boolean = location.pathname
        .split('/')
        .some((path) => path === 'engage')

    const engageClassifiersList = [...EMOTIONS_LIST]

    const { data, loading } = useGetModerationBoundariesQuery({
        variables: { accountId: content.account.id },
    })
    const { data: classifierDetails } = useGetClassifierDetailsQuery()

    // Is the user an admin?
    const [admin] = useRecoilState(isUserAdminState)

    if (!!!content) {
        return <></>
    }

    if (loading) {
        return <div>Loading...</div>
    }

    // To determine if a classification has contributed to a moderation we need
    // to know all the boundaries for this content
    const configuredBoundaries =
        data?.getModerationBoundaries || ([] as ModerationBoundaryView[])

    // We require all the classifiers so that we can use the display names
    const classifiers =
        classifierDetails?.getClassifierDetails || ([] as ClassifierDetails[])

    let classifiersToDisplay: ClassifiersToDisplay = {
        // We split the classifications into the moderation they contribute to
        relevantSevereClassifications: [],
        relevantSuspectClassifications: [],
        // Always show these classifications
        alwaysShowClassifications: [],
        // All the rest go in safe apart from the engage classifiers
        safeClassifications: [],
        engageClassifications: [],
    }

    if (configuredBoundaries && classifiers) {
        // Convert to a map for speedier lookup
        const classifiersMap = new Map<string, ClassifierDetails>(
            classifiers.map((classifier) => {
                return [classifier.shortName.toLowerCase(), classifier]
            })
        )

        classifiersToDisplay = getClassifiersToDisplay(
            content,
            configuredBoundaries,
            classifiersMap,
            isEngagePage,
            searchParameters,
            engageClassifiersList
        )
    }

    function handleShowAllOnClick() {
        setShowAll(!showAll)
    }

    return (
        <div className="flex flex-row flex-wrap items-center gap-3 gap-y-3">
            {classifiersToDisplay.relevantSevereClassifications.map(
                (classification) => (
                    <span
                        key={`${classification.classifierName}_${classification.classification}_${classification.severity}_${content.account.id}_${content.id}`}
                    >
                        <ClassificationDisplay
                            queue="SEVERE"
                            classification={classification}
                            accountId={content.account.id}
                            contentId={content.id}
                        />
                    </span>
                )
            )}
            {classifiersToDisplay.relevantSuspectClassifications.map(
                (classification) => (
                    <span
                        key={`${classification.classifierName}_${classification.classification}_${classification.severity}_${content.account.id}_${content.id}`}
                    >
                        <ClassificationDisplay
                            queue="SUSPECT"
                            classification={classification}
                            accountId={content.account.id}
                            contentId={content.id}
                        />
                    </span>
                )
            )}

            {classifiersToDisplay.alwaysShowClassifications.map(
                (classification) => (
                    <span
                        key={`${classification.classifierName}_${classification.classification}_${classification.severity}_${content.account.id}_${content.id}`}
                    >
                        <ClassificationDisplay
                            queue="SAFE"
                            classification={classification}
                            accountId={content.account.id}
                            contentId={content.id}
                        />
                    </span>
                )
            )}

            {admin && classifiersToDisplay.safeClassifications.length > 0 && (
                <ShowHideChevronToggle
                    showAll={showAll}
                    onShowAllChange={handleShowAllOnClick}
                />
            )}

            {showAll &&
                classifiersToDisplay.safeClassifications.map(
                    (classification) => {
                        return (
                            <span
                                key={`${classification.classifierName}_${classification.classification}_${classification.severity}_${content.account.id}_${content.id}`}
                            >
                                <ClassificationDisplay
                                    queue="SAFE"
                                    classification={classification}
                                    accountId={content.account.id}
                                    contentId={content.id}
                                />
                            </span>
                        )
                    }
                )}
        </div>
    )
}
