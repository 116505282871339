import { formatDistanceToNow, formatISO9075 } from 'date-fns'
import { getInLocalTime } from '../../util/timeHelpers'

export function StandardDateDisplay({ date }: { date: string }) {
    return date ? formatISO9075(getInLocalTime(date)) : null
}

export function RelativeDateDisplay({ date }: { date: string }) {
    return date
        ? formatDistanceToNow(getInLocalTime(date), {
              addSuffix: true,
          })
        : null
}
