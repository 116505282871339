import { AccountRecord, SocialMediaServiceEnum } from '../../api/client'
import User from '../../models/User'

export function canUseBulkActions(user: User, account?: AccountRecord) {
    /**
     * The User should only be able to see the selector and bulk actions toolbar under the following conditions:
     * - The account is a Facebook account as currently there is only one bulk action, liking, which is only available on Facebook.
     * -    => If the account is not defined then the bulk action should be displayed as the user may be searching across multiple accounts
     * - The user has permission to like content - as there is currently only one bulk action, liking, then if they don't have permission it makes no sense to see the selector
     * -    OR if the user has permission to use the read status features.
     *
     * Lets create a matric for this so its super clear
     *
     * Single account - i.e. the account page
     * like | readStatus | isFacebook | canUseBulkActions
     *   1  |     1      \      1     |         1
     *   0  |     1      \      1     |         1
     *   1  |     0      \      1     |         1
     *   0  |     0      \      1     |         0
     *   1  |     1      \      0     |         1
     *   0  |     1      \      0     |         1
     *   1  |     0      \      0     |         0
     *   0  |     0      \      0     |         0
     *
     * Logic for single account is quite complex and I can't find a way of succinctly writing it with ANDs/ORs/XORs etc.
     *
     *
     * Multiple-account page
     * like | readStatus | canUseBulkActions
     *   1  |     1      \         1
     *   0  |     1      \         1
     *   1  |     0      \         1
     *   0  |     0      \         0
     * logic for multiple account is
     * => like || readStatus
     *
     * This is all tested in bulk-actions.test.ts
     */

    if (account) {
        // If this is for a single account then we implement the logic above
        if (
            account?.socialMediaServiceShortName?.toLowerCase() ===
            SocialMediaServiceEnum.Facebook.toLowerCase()
        ) {
            return user.canLikeContent || user.canUseReadStatus
        } else {
            return user.canUseReadStatus
        }
    } else {
        // There can be multiple accounts so we always show the bulk actions if the user has at least one of these actions enabled
        return user.canLikeContent || user.canUseReadStatus
    }
}
