type Props = {
    moderationStyle: string | undefined
    socialMediaServiceName: string
    iconName: string
    isPublic: boolean
    rounded?: boolean
    isSelected: boolean
}
export default function ContentSidebar(props: Props) {
    const {
        moderationStyle,
        socialMediaServiceName,
        iconName,
        isPublic,
        rounded,
    } = props

    return (
        <div
            className={`bg-gray-500 bg-${moderationStyle} ${
                rounded ? 'rounded-tl-lg rounded-bl-lg' : ''
            } flex flex-col items-center justify-between pl-3 pr-3 text-2xl text-white  `}
        >
            <i
                data-tooltip-id="tooltip-id"
                data-tooltip-content={`This content comes from from ${socialMediaServiceName}`}
                className={`fab mt-4 ${iconName} w-6`}
            ></i>

            {isPublic ? (
                <>
                    <p
                        data-tooltip-id="tooltip-id"
                        data-tooltip-content={`This content has come from a mention`}
                        className="mb-4 w-6"
                    >
                        @
                    </p>
                </>
            ) : (
                <>
                    <i
                        data-tooltip-id="tooltip-id"
                        data-tooltip-content={`This content has come from a direct message`}
                        className="fas fa-envelope mb-4 w-6"
                    ></i>
                </>
            )}
        </div>
    )
}
