import { EnvelopeIcon, EnvelopeOpenIcon } from '@heroicons/react/24/outline'
import { useRecoilState } from 'recoil'
import { ContentOutcomeView } from '../../../api/client'
import { userState } from '../../../store/DomainStore'
import BaseActionButton from './BaseActionButton'

type Props = {
    content: ContentOutcomeView
    onMarkAsReadClick: (content: ContentOutcomeView) => void
    onMarkAsUnreadClick: (content: ContentOutcomeView) => void
    loading: boolean
}

export default function ReadStatusButton(props: Props) {
    const { content, onMarkAsReadClick, onMarkAsUnreadClick, loading } = props
    const [user] = useRecoilState(userState)

    const readByMe = !!content.readReceipts.find(
        (receipt) => receipt.userId === user.id
    )

    return (
        <div className="relative">
            {readByMe && (
                <BaseActionButton
                    tooltip={`Mark as unread.`}
                    loading={loading}
                    colour="primary"
                    actionAlreadyApplied={!readByMe}
                    icon={<EnvelopeOpenIcon />}
                    onActionClick={async () => {
                        onMarkAsUnreadClick(content)
                    }}
                    isActionQueued={false}
                />
            )}
            {!readByMe && (
                <BaseActionButton
                    tooltip={`Mark as read.`}
                    loading={loading}
                    colour="primary"
                    actionAlreadyApplied={!readByMe}
                    icon={<EnvelopeIcon />}
                    onActionClick={async () => {
                        onMarkAsReadClick(content)
                    }}
                    isActionQueued={false}
                />
            )}
        </div>
    )
}
