import { InformationCircleIcon } from '@heroicons/react/24/outline'

type Props = {
    tooltip: string
    onActionClick: () => void
}

export default function InfoButton(props: Props) {
    const { tooltip, onActionClick } = props

    return (
        <button
            data-tooltip-id="tooltip-id"
            data-tooltip-content={tooltip}
            className="w-6 h-6 p-0 transition duration-300 ease-in-out text-gray-700"
            type="button"
            onClick={() => onActionClick()}
        >
            <div className="relative">
                <InformationCircleIcon />
            </div>
        </button>
    )
}
