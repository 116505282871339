import mixpanel from 'mixpanel-browser'
import { useState } from 'react'
import { ContentOutcomeView, EnrichedContent } from '../../../../api/client'
import { isAd } from '../../../../util/ads'
import InfoButton from '../../../actions/buttons/InfoButton'
import {
    RelativeDateDisplay,
    StandardDateDisplay,
} from '../../../date/StandardDateDisplay'
import ContentMetadataModal from './ContentMetadataModal'

type Props = {
    content: ContentOutcomeView
    enrichedContent: EnrichedContent | undefined
}

export default function ContentMetadata(props: Props) {
    const [open, setOpen] = useState(false)
    const { content, enrichedContent } = props

    const showAdLabel = isAd(content)

    /**
     * The name we want to display might be a handle, e.g. '@jamescolepotter' or
     * it might be a name, e.g. James Cole Pottery. This depends on the service.
     */
    return (
        <div className="flex flex-row">
            {content.serviceAuthorName && (
                <div className="pr-2 font-medium">
                    {content.serviceAuthorName}
                </div>
            )}
            {content.serviceAuthorDisplayUsername &&
                content.socialMediaAuthorLink && (
                    <div className="text-primary-600 hover:text-primary-900 pr-1">
                        <a
                            href={content.socialMediaAuthorLink || ''}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {content.serviceAuthorDisplayUsername}
                        </a>
                    </div>
                )}
            {content.serviceAuthorDisplayUsername &&
                !!!content.socialMediaAuthorLink && (
                    <div className="pr-1 text-gray-700">
                        {content.serviceAuthorDisplayUsername}
                    </div>
                )}

            <div className="pr-1 text-gray-700">·</div>
            <div
                className="pr-1 text-gray-700"
                data-cy-date={content.datePosted}
            >
                <RelativeDateDisplay date={content.datePosted} />
            </div>
            <div className="pr-1 text-gray-600">
                ( <StandardDateDisplay date={content.datePosted} />
            </div>
            <div>
                <InfoButton
                    tooltip="Show all details"
                    onActionClick={() => {
                        mixpanel.track('Content - info button clicked', {
                            accountId: content.account?.id,
                            contentId: content.id,
                        })
                        setOpen(true)
                    }}
                />
            </div>
            <ContentMetadataModal
                open={open}
                setOpen={setOpen}
                content={content}
                enrichedContent={enrichedContent}
            />
            {showAdLabel ? (
                <>
                    <span
                        data-tooltip-id="tooltip-id"
                        data-tooltip-content="This comment was on an ad"
                        className="ml-auto inline-flex items-center rounded-md bg-green-50 px-1 py-0 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                    >
                        Ad
                    </span>
                </>
            ) : undefined}
        </div>
    )
}
