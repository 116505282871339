import { useGetUserIfAllowedQuery } from '../../api/client'

type Props = {
    user_id: number
}

/**
 * Displays the username of an Arwen user.
 *
 * E.g. david@arwen.ai
 */
export default function ArwenUser(props: Props) {
    const { user_id } = props

    const { data, loading } = useGetUserIfAllowedQuery({
        variables: {
            id: user_id,
        },

        onError: (error) => {
            console.error('There was an error fetching user details', error)
        },
    })

    if (!data?.getUserIfAllowed || loading) {
        return <div>...loading user details...</div>
    }

    return <div>{data?.getUserIfAllowed.username}</div>
}
