import { useEffect, useState } from 'react'
import {
    AccountRecord,
    useEditAccountTeamMutation,
    useSearchTeamsQuery,
} from '../../api/client'
import { toastError, toastSuccess } from '../Notification'
import ComboboxMenu from '../inputs/ComboBox'
import { ComboboxMenuOption } from '../inputs/ComboboxMenuOption'

type Props = {
    account: AccountRecord
    onTeamChange: () => void
}

export default function AccountTeamSelector(props: Props) {
    const { account, onTeamChange } = props
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedTeam, setSelectedTeam] =
        useState<ComboboxMenuOption | null>()

    const { data: searchTeamsData, loading: searchTeamsLoading } =
        useSearchTeamsQuery({
            variables: { params: { searchTerm: searchTerm } },
        })

    const [editAccountTeamMutation, { loading: editAccountTeamLoading }] =
        useEditAccountTeamMutation({
            onError: (error) => {
                toastError(error.message)
            },
            onCompleted: (result) => {
                onTeamChange()
                const teamId = result.editAccountTeam.teamId
                if (teamId) {
                    const selectedTeam = searchTeamsData?.searchTeams?.find(
                        (team) => team.id === teamId
                    )
                    if (selectedTeam) {
                        toastSuccess(
                            `Profile "${account.accountName}" is now in team '${selectedTeam.name}"`
                        )
                    }
                } else {
                    toastSuccess(
                        `Profile "${account.accountName}" is not in a team`
                    )
                }
            },
        })

    const teams = searchTeamsData?.searchTeams ?? []

    const options = teams.map((team) => {
        return { id: team.id, name: team.name }
    })

    useEffect(() => {
        if (account.teamId === selectedTeam?.id) return
        if (account.teamId) {
            setSelectedTeam({
                id: account.teamId,
                name: account.teamName || '',
            })
        } else {
            setSelectedTeam({
                id: 0,
                name: '',
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account.teamId])

    const currentValue: ComboboxMenuOption = {
        id: account.teamId || 0,
        name: account.teamName || '',
    }

    return (
        <div className="flex flex-row justify-evenly">
            <div className="grow">
                <ComboboxMenu
                    label={'Select team'}
                    value={selectedTeam || currentValue}
                    options={options}
                    onChange={async (e: ComboboxMenuOption) => {
                        if (e && e.id) {
                            // Update the team for the account
                            editAccountTeamMutation({
                                variables: {
                                    accountId: account.id,
                                    teamId: e.id,
                                },
                            })

                            // Optimistically set the team in the UI
                            setSelectedTeam({
                                id: e.id,
                                name: e.name,
                            })
                        }
                    }}
                    onQueryChange={setSearchTerm}
                    isLoading={searchTeamsLoading || editAccountTeamLoading}
                    isSaving={editAccountTeamLoading}
                    canCreate={false}
                />
            </div>

            <button
                type="reset"
                onClick={() => {
                    editAccountTeamMutation({
                        variables: {
                            accountId: account.id,
                            teamId: null,
                        },
                    })
                    setSelectedTeam({
                        id: 0,
                        name: '',
                    })
                }}
                className="py-2 px-6 underline self-end"
            >
                Remove from team
            </button>
        </div>
    )
}
