import { DialogPanel, DialogTitle } from '@headlessui/react'
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline'
import {
    ContentAction,
    ContentActionView,
    ContentOutcomeView,
    EnrichedContent,
    ProfileView,
} from '../../api/client'
import CloseButton from '../CloseButton'
import SimpleModal from '../SimpleModal'
import Spinner from '../Spinner'
import ContentCard from '../content/content-card/ContentCard'
import NewReply from './NewReply'
import ReplyUs from './ReplyUs'
import { useState } from 'react'
import SuggestedRepliesList from './SuggestedRepliesList'
import { clsx } from 'clsx'
import SuggestedRepliesUpsell from './SuggestedRepliesUpsell'

type Props = {
    open: boolean
    setOpen: (open: boolean) => void
    content: ContentOutcomeView
    onReply: (message: string) => void
    savingReply: boolean
    onActionClick: (contentActions: ContentActionView[]) => void
    profileCache: ProfileView[]
    actionCacheMap: Map<number, ContentActionView[]>
}

/**
 * Modal that allows the user to reply and react to content.
 */
export default function ReplyModal(props: Props) {
    const {
        open,
        setOpen,
        content,
        onReply,
        savingReply,
        onActionClick,
        profileCache,
        actionCacheMap,
    } = props

    // Identify the relevant actions from the action cache map
    const allExistingContentActions = actionCacheMap.get(content.id) || []

    const replyActions = allExistingContentActions.filter(
        (actions) => actions.action === ContentAction.Reply
    )

    const userHasResponded = replyActions.length > 0

    const [selectedReply, setSelectedReply] = useState('')

    const [suggestedReplies, setSuggestedReplies] = useState<string[]>()

    const llmRepliesEnabled = content.account.llmEnableSuggestedReplies || false

    return (
        <SimpleModal
            open={open}
            onClose={() => {
                setOpen(false)
            }}
        >
            <DialogPanel
                className={clsx(
                    'border-complementary-700 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all duration-300',
                    'min-w-min max-w-4xl sm:w-fit'
                )}
            >
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4 mb-6 ">
                    <div className="sm:flex sm:items-start">
                        <div className="mt-4 text-center sm:ml-4 sm:mt-0 sm:text-left">
                            <DialogTitle
                                as="h1"
                                className="text-2xl leading-6 text-gray-900 flex flex-row pb-2 border-b border-gray-300"
                            >
                                <div className="flex flex-row justify-between w-full">
                                    <div className="mx-auto  flex h-14 w-14 flex-shrink-0 items-center justify-center sm:mx-0 sm:h-10 sm:w-10">
                                        <ChatBubbleLeftRightIcon
                                            className="h-8 w-8 text-gray-700"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="mt-2 ml-3">
                                        Your replies
                                    </div>
                                    <div className="ml-6 self-center flex-1">
                                        {savingReply && <Spinner size={6} />}
                                    </div>

                                    <CloseButton
                                        onClick={() => {
                                            setSuggestedReplies([])
                                            setOpen(false)
                                        }}
                                    />
                                </div>
                            </DialogTitle>

                            <div className="mt-14 mb-8 ml-2 mr-6 min-w-[750px]">
                                <div className="hover:bg-gray-50 border border-gray-300 rounded-lg shadow-md">
                                    <ContentCard
                                        content={content}
                                        showAccount={false}
                                        onContentAction={(
                                            contentActions: ContentActionView[]
                                        ) => onActionClick(contentActions)}
                                        enriching={false}
                                        translating={false}
                                        profileCache={profileCache}
                                        //TODO: set these up
                                        enrichedContentMap={
                                            new Map<number, EnrichedContent>()
                                        }
                                        actionCacheMap={actionCacheMap}
                                        rounded={true}
                                        isSelected={false}
                                        // Do not define a function for selection change.
                                        // This will disable the selection feature in the ContentCard
                                        onSelectionChange={() => {}}
                                        onMarkAsReadClick={() => {}}
                                        onMarkAsUnreadClick={() => {}}
                                    />
                                </div>

                                <div className="border-gray-400 w-16 h-20 border-l-2 border-b-2 border-dashed absolute rounded-bl-lg left-16"></div>

                                {replyActions?.map((replyAction) => {
                                    return (
                                        <div
                                            className="mt-10 ml-8 opacity-100 relative border border-gray-300 hover:bg-gray-50 shadow-md rounded-lg"
                                            key={replyAction.id}
                                        >
                                            <ReplyUs
                                                content={content}
                                                action={replyAction}
                                            />
                                        </div>
                                    )
                                })}
                                {!userHasResponded && (
                                    <div className="mt-10 ml-8 opacity-100 relative border border-gray-300 shadow-md rounded-lg">
                                        <NewReply
                                            onReply={async (message) => {
                                                onReply(message)
                                            }}
                                            sendingReply={savingReply}
                                            initialReply={selectedReply}
                                        />
                                    </div>
                                )}
                                {!llmRepliesEnabled && (
                                    <SuggestedRepliesUpsell />
                                )}
                                {llmRepliesEnabled && !userHasResponded && (
                                    <SuggestedRepliesList
                                        onSelection={setSelectedReply}
                                        suggestedReplies={suggestedReplies}
                                        setSuggestedReplies={
                                            setSuggestedReplies
                                        }
                                        content={content}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </DialogPanel>
        </SimpleModal>
    )
}
