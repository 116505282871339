import { ContentOutcomeView } from '../../../api/client'

type Props = {
    content: ContentOutcomeView
    isSelected: boolean
    onSelectionChange: (
        content: ContentOutcomeView,
        isSelected: boolean
    ) => void
}

/**
 * This is used in the ContentCard component to allow the user to select content.
 */
export default function ContentSelector(props: Props) {
    const { content, isSelected, onSelectionChange } = props

    const classes = isSelected
        ? ' opacity-100 '
        : ' opacity-0 group-hover:opacity-100 '

    return (
        <div
            className={`flex transition-opacity duration-300 items-center justify-center absolute h-full  w-12 hover:cursor-pointer  bg-primary-500 ${classes}`}
            onClick={() => {
                onSelectionChange(content, !isSelected)
            }}
        >
            <input
                id="comments"
                aria-describedby="comments-description"
                name="comments"
                type="checkbox"
                checked={isSelected}
                onChange={() => {
                    onSelectionChange(content, !isSelected)
                }}
                className="opacity-100 h-4 w-4 rounded border-gray-300 text-accent-2-500 focus:ring-accent-2-600"
            />
        </div>
    )
}
