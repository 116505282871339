import { HandThumbUpIcon } from '@heroicons/react/24/outline'
import {
    ContentAction,
    ContentActionView,
    ContentOutcomeView,
    useLikeContentMutation,
} from '../../../api/client'
import { isActionApplied } from '../../content/content-card/actions'
import { toastError, toastSuccess, toastWarning } from '../../Notification'
import BaseActionButton from './BaseActionButton'

type Props = {
    contents: ContentOutcomeView[]
    onActionClick: (actions: ContentActionView[], queued?: boolean) => void
    actions: ContentActionView[]
    disabled?: boolean
    disabledMessage?: string
}

export default function LikeButton(props: Props) {
    const { contents, onActionClick, actions, disabled, disabledMessage } =
        props

    const [likeContentMutation, { loading }] = useLikeContentMutation({
        onCompleted: (data) => {
            // This button can be used to like a single item or many at a time. Let's check to see how successful we were.
            if (data.likeContent) {
                // So we can see that there is data and that the mutation was "successful"
                // Now we need to check to see if any requests failed and if so report them upwards
                const contentTotal = data.likeContent.length
                const contentActioned = data.likeContent.filter(
                    (contentAction) => contentAction.success
                ).length
                const contentNotActioned = data.likeContent.filter(
                    (contentAction) => !contentAction.success
                ).length

                const allSuccess = data.likeContent.every(
                    (contentAction) => contentAction.success
                )
                const allFailure = data.likeContent.every(
                    (contentAction) => !contentAction.success
                )

                if (allSuccess) {
                    toastSuccess(`${contentTotal} item(s) liked`)
                }

                if (allFailure) {
                    toastError(`Unable to like any content.`)
                }

                if (!allSuccess && !allFailure) {
                    toastWarning(
                        `Liked ${contentActioned}/${contentTotal} items. \n\nFailed to like ${contentNotActioned} items. \n\nLike may not be supported on this platform, these items may have been removed or there may be a problem with authroisation`
                    )
                }

                const contentActions = data.likeContent
                    .filter(
                        (contentAction) =>
                            contentAction !== null &&
                            contentAction !== undefined &&
                            contentAction.success
                    )
                    .map(
                        (contentAction) =>
                            contentAction.contentAction as ContentActionView
                    )

                onActionClick(contentActions)
            }
        },
        onError: (error) => {
            toastError(error.message)
        },
    })

    const actionAlreadyApplied = isActionApplied(actions, ContentAction.Like)

    async function handleActionClick() {
        // If only one is selected then just call the action. A user may wish to like or unlike
        if (contents.length === 1) {
            await likeContentMutation({
                variables: {
                    like: !actionAlreadyApplied,
                    contentIds: contents.map((content) => content.id),
                },
            })
        } else {
            // If multiple items are liked then this is a bulk action.

            // Remove the items that have already been liked
            const contentNotAlreadyLiked = contents.filter(
                (content) =>
                    content.actions.filter(
                        (action) =>
                            action.action === ContentAction.Like &&
                            action.active === true
                    ).length === 0
            )

            // Only call the like function if there is content to like
            if (contentNotAlreadyLiked.length > 0) {
                await likeContentMutation({
                    variables: {
                        like: !actionAlreadyApplied,
                        contentIds: contentNotAlreadyLiked.map(
                            (content) => content.id
                        ),
                    },
                })
            } else {
                toastWarning('You have already liked all selected content')
            }
        }
    }

    return (
        <div className="relative">
            <BaseActionButton
                tooltip="Like/unlike this comment"
                loading={loading}
                colour="green"
                actionAlreadyApplied={actionAlreadyApplied}
                icon={<HandThumbUpIcon />}
                onActionClick={handleActionClick}
                isActionQueued={false}
                disabled={disabled}
                disabledMessage={disabledMessage}
            />
        </div>
    )
}
