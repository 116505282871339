import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'
import { getPageDetails } from '../util/pagination'
import clsx from 'clsx'

type Props = {
    offset: number
    limit: number
    total: number
    onChange: (offset: number, limit: number) => void
    loading?: boolean
}

export default function Pagination(props: Props) {
    const { offset, limit, total, onChange, loading } = props

    const pages = Math.ceil(total / limit)
    const hasPrevious = offset > 0
    const hasNext = offset + limit < total

    if (loading) {
        return (
            <div className="border-gray-300 border-solid border flex animate-pulse flex-row items-stretch content-center h-full w-full justify-center space-x-5">
                <div className="w-full bg-gray-300 h-10 rounded-sm"></div>
            </div>
        )
    }

    function handlePageClick(page: number) {
        onChange((page - 1) * limit, limit)
    }

    function handlePreviousClick() {
        onChange(offset - limit, limit)
    }

    function handleNextClick() {
        onChange(offset + limit, limit)
    }

    function handleSetLimit(newLimit: number) {
        onChange(offset, newLimit)
    }

    const normalClassNames = `bg-white border-gray-300 text-gray-500 hover:bg-gray-50`
    const activeClassNames = `bg-primary-50 border-primary text-primary`

    function generatePageButtons() {
        const buttons = []

        // We don't want to show too many page buttons, let's say 5 max
        const maxDisplayButtons = 7

        const pageDetails = getPageDetails(
            maxDisplayButtons,
            total,
            offset,
            limit
        )

        if (pageDetails.firstPageToDisplay > 1) {
            buttons.push(
                <button
                    key={'1'}
                    onClick={() => handlePageClick(1)}
                    aria-current="page"
                    className={clsx(
                        normalClassNames,
                        `hidden md:inline-flex relative items-center px-4 py-2 border text-sm `
                    )}
                >
                    <span>1</span>
                </button>
            )
            if (pageDetails.firstPageToDisplay > 2) {
                buttons.push(
                    <button
                        key={'first'}
                        aria-current="page"
                        className={clsx(
                            normalClassNames,
                            ` hidden md:inline-flex relative items-center px-4 py-2 border text-sm `
                        )}
                    >
                        <span>&#8230;</span>
                    </button>
                )
            }
        }

        for (
            let i = pageDetails.firstPageToDisplay;
            i <= pageDetails.lastPageToDisplay;
            i++
        ) {
            const isCurrent = Math.round(offset / limit) + 1 === i

            buttons.push(
                isCurrent ? (
                    <button
                        key={i}
                        onClick={() => handlePageClick(i)}
                        aria-current="page"
                        className={clsx(
                            activeClassNames,
                            `z-10 relative inline-flex items-center px-4 py-2 border text-sm`
                        )}
                    >
                        {i}
                    </button>
                ) : (
                    <button
                        key={i}
                        onClick={() => handlePageClick(i)}
                        aria-current="page"
                        className={clsx(
                            normalClassNames,
                            'hidden md:inline-flex relative items-center px-4 py-2 border text-sm '
                        )}
                    >
                        {i}
                    </button>
                )
            )
        }

        if (pageDetails.lastPageToDisplay < pageDetails.totalPages) {
            if (pageDetails.lastPageToDisplay < pageDetails.totalPages - 1) {
                buttons.push(
                    <button
                        key={'last'}
                        aria-current="page"
                        className={clsx(
                            normalClassNames,
                            'hidden md:inline-flex relative items-center px-4 py-2 border text-sm '
                        )}
                    >
                        <span>&#8230;</span>
                    </button>
                )
            }
            buttons.push(
                <button
                    key={pages}
                    aria-current="page"
                    onClick={() => handlePageClick(pages)}
                    className={clsx(
                        normalClassNames,
                        'hidden md:inline-flex relative items-center px-4 py-2 border text-sm '
                    )}
                >
                    {pages}
                </button>
            )
        }

        return buttons
    }

    return (
        <div className="bg-white flex items-center justify-between ">
            {/* TODO: this is for smaller screens but currently won't work very well as nothing else is responsive */}
            {/* <div className="flex-1 flex justify-between sm:hidden">
                <button
                    onClick={handlePreviousClick}
                    disabled={!hasPrevious}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm  rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    Previous
                </button>
                <button
                    onClick={handleNextClick}
                    disabled={!hasNext}
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm  rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    Next
                </button>
            </div> */}
            <div className="flex flex-row items-center">
                <div>
                    <nav
                        className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                        aria-label="Pagination"
                    >
                        <select
                            id="limit"
                            name="limit"
                            className="mr-2 block h-full w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary rounded-md"
                            defaultValue={limit}
                            onChange={(
                                event: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                                const newLimit = event.target.value
                                handleSetLimit(Number(newLimit))
                            }}
                        >
                            <option>5</option>
                            <option>10</option>
                            <option>20</option>
                            <option>50</option>
                        </select>
                        {total > limit ? (
                            <>
                                <button
                                    onClick={handlePreviousClick}
                                    disabled={!hasPrevious}
                                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm  text-gray-500 hover:bg-gray-50"
                                >
                                    <span className="sr-only">Previous</span>
                                    <ChevronLeftIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </button>

                                {generatePageButtons()}
                                <button
                                    onClick={handleNextClick}
                                    disabled={!hasNext}
                                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm  text-gray-500 hover:bg-gray-50"
                                >
                                    <span className="sr-only">Next</span>
                                    <ChevronRightIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </button>
                            </>
                        ) : null}
                    </nav>
                </div>
                <div>
                    <p className="pl-6 text-sm text-gray-700">
                        Showing <span className="">{offset + 1}</span> to{' '}
                        <span className="">
                            {offset + limit > total ? total : offset + limit}
                        </span>{' '}
                        of <span className="">{total}</span> results
                    </p>
                </div>
            </div>
        </div>
    )
}
