import { useFormContext, useFormState } from 'react-hook-form'
import {
    SocialMediaServiceEnum,
    useFetchUserDetailsLazyQuery,
} from '../../api/client'
import ValidatingInput from './ValidatingInput'
import { useEffect } from 'react'

export const NAME = 'channelId'

function YouTubeChannelIDInput() {
    const form = useFormContext()
    const { errors } = useFormState({ control: form.control })

    const [fetchChannelDetails, { loading: loadingDetails, error }] =
        useFetchUserDetailsLazyQuery({
            fetchPolicy: 'no-cache',

            onCompleted: (data) => {
                const error = data?.fetchUserDetails?.error
                const details = data?.fetchUserDetails?.userDetails
                if (error) {
                    form.setError(NAME, {
                        type: error?.type!,
                        message: error?.message!,
                    })
                } else if (details) {
                    form.setValue('username', details.username)
                    form.setValue('serviceAccountId', details.serviceAccountId)
                    form.setValue('name', details.name)
                } else {
                    form.setError(NAME, {
                        type: 'errors',
                        message: 'Unable to check channel ID',
                    })
                }
            },
            onError: () => {
                form.setError(NAME, {
                    type: 'errors',
                    message: error?.message,
                })
            },
        })

    useEffect(() => {
        // Register the 'hidden' fields programmatically
        form.register('username')
        form.register('serviceAccountId')
        form.register('name')
    }, [form])

    const clearForm = () => {
        form.setValue('username', '')
        form.setValue('serviceAccountId', '')
        form.setValue('name', '')
    }

    const onChannelIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newChannelId = event.target.value
        form.setValue(NAME, newChannelId, { shouldValidate: true })
        clearForm()
        if (newChannelId === '') {
            form.setError(NAME, {
                type: 'required',
                message: 'Channel ID is required',
            })
        }
        // If we've got a reasonable number of characters then we'll try to fetch a match
        else if (newChannelId.length > 2) {
            fetchChannelDetails({
                variables: {
                    socialMediaService: SocialMediaServiceEnum.Youtube,
                    serviceAccountUsername: newChannelId,
                },
            })
        }
    }

    const channelName = form.watch('name')
    return (
        <div className="mt-1 sm:mt-0 sm:col-span-2 flex flex-col items-left">
            <ValidatingInput
                onChange={onChannelIdChange}
                name={NAME}
                isLoading={loadingDetails}
                error={errors.channelId}
            />

            <div>
                {channelName && (
                    <span className="text-sm font-bold text-green-700">
                        {channelName}
                    </span>
                )}
                {errors.channelId && (
                    <span className="text-yellow-600 text-sm">
                        {typeof errors.channelId.message === 'string'
                            ? errors.channelId.message
                            : 'Unknown error'}
                    </span>
                )}
            </div>
        </div>
    )
}

export default YouTubeChannelIDInput
