import { Fragment } from 'react'
import { Dialog, Transition, TransitionChild } from '@headlessui/react'

type Props = {
    open: boolean
    onClose: () => void
    children: React.ReactNode
}

/**
 * This simple reusable component can contain any contents, and will close when clicked externally.
 */
function SimpleModal(props: Props) {
    const { open, onClose } = props

    return (
        <Transition appear show={open} as={Fragment}>
            <Dialog className="relative z-30" onClose={onClose}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </TransitionChild>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div className="">{props.children}</div>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export default SimpleModal
