import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
    PromptViewItemFragment,
    useDeleteLlmClassificationPromptMutation,
    useGetLlmClassificationPromptsQuery,
} from '../../api/client'
import { toastError, toastSuccess } from '../Notification'
import SimpleLoader from '../SimpleLoader'

export default function PromptTable({ accountId }: { accountId: number }) {
    const location = useLocation()
    const { data, loading, refetch } = useGetLlmClassificationPromptsQuery({
        variables: { accountId: accountId },
        notifyOnNetworkStatusChange: true,
        onError: (error) => {
            toastError(error.message)
        },
    })

    const [deleteLlmClassificationprompt, { loading: deleteLoading }] =
        useDeleteLlmClassificationPromptMutation({
            onError: (error) => {
                toastError(error.message)
            },
            onCompleted: async () => {
                toastSuccess('Prompt deleted')
                await refetch()
            },
        })

    const prompts = data?.getLlmClassificationPrompts
    useEffect(
        () => {
            refetch()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location.pathname]
    )

    async function deletePrompt(prompt: PromptViewItemFragment) {
        if (window.confirm('Are you sure you want to delete this prompt?')) {
            await deleteLlmClassificationprompt({
                variables: { promptId: prompt.id },
                onError: (error) => {
                    toastError(error.message)
                },
                onCompleted: async () => {
                    await refetch()
                    toastSuccess('Prompt deleted')
                },
            })
        }
    }

    return (
        <div>
            {(loading || deleteLoading) && <SimpleLoader loading={true} />}

            {prompts &&
                prompts.length > 0 &&
                prompts.map((prompt: PromptViewItemFragment) => {
                    return (
                        <div
                            key={prompt.id}
                            className="max-w rounded overflow-hidden shadow-lg m-4 p-2 bg-white border border-gray-300"
                        >
                            <div className="px-3 flex justify-between items-center">
                                <h2 className="my-1 text-lg font-bold">
                                    Name: {prompt.category}
                                </h2>
                                <span className="text-gray-600 text-sm">
                                    Created on{' '}
                                    {new Date(prompt.createdAt).toISOString()}
                                </span>
                            </div>
                            <div className="px-3 flex justify-between items-center">
                                <p className="text-gray-700 text-base flex-grow line-clamp-2">
                                    {prompt.prompt}
                                </p>
                                <a
                                    href={`#/account/${accountId}/settings/prompt/${prompt.id}/`}
                                    className="text-primary-600 underline hover:text-primary-700 text-base"
                                    data-tooltip-id="tooltip-id"
                                    data-tooltip-content="Edit this prompt"
                                    data-for="editPromptLink"
                                >
                                    Edit
                                </a>

                                <button
                                    onClick={() => deletePrompt(prompt)}
                                    className="text-red-600 underline hover:text-red-700 text-base ml-4"
                                >
                                    Delete
                                </button>
                            </div>
                        </div>
                    )
                })}
        </div>
    )
}
