import { useEffect } from 'react'
import { useGetSocialMediaServicesQuery } from '../api/client'
import useContentSearchParams from '../hooks/useContentQueryParams'
import ContentSearchBar from '../components/content/search/ContentSearchBar'
import { useRecoilState } from 'recoil'
import {
    isUserAdminState,
    userState,
    userTeamsState,
} from '../store/DomainStore'
import ContentList from '../components/content/ContentList'
import { canUseBulkActions } from '../components/content/bulk-actions'

function ContentSearchPage() {
    const {
        searchParameters,
        onSearchParameterChange,
        allSocialMediaServices,

        setAllSocialMediaServices,
    } = useContentSearchParams()

    const [admin] = useRecoilState(isUserAdminState)
    const [user] = useRecoilState(userState)
    const [userTeams] = useRecoilState(userTeamsState)
    // We want to show the Team drop down only if it will be useful to users. Which means they are either an admin or they are part of more than one team.
    const showTeamSearch = admin || userTeams.length > 1

    // The selected social media services
    const { data: socialMediaServicesData } = useGetSocialMediaServicesQuery({})

    useEffect(() => {
        if (socialMediaServicesData?.getSocialMediaServices) {
            setAllSocialMediaServices(
                socialMediaServicesData?.getSocialMediaServices
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socialMediaServicesData?.getSocialMediaServices])

    const enableBulkActions = canUseBulkActions(user)

    return (
        <>
            <ContentSearchBar
                searchParameters={searchParameters}
                onSearchParameterChange={onSearchParameterChange}
                allSocialMediaServices={allSocialMediaServices}
                showProfileSearch={true}
                showSocialMediaServiceSearch={true}
                showEmotionsSearch={false}
                showTopicsSearch={false}
                showTeamSearch={showTeamSearch}
                showReadStatusSearch={user.canUseReadStatus}
            />
            <ContentList
                searchParameters={searchParameters}
                showOwningAccount={true}
                enableBulkActions={enableBulkActions}
            ></ContentList>
        </>
    )
}

export default ContentSearchPage
