import { useState } from 'react'
import {
    ContentClassifierMetadataView,
    SearchParamsForContentOutcome,
    useGetContentClassifierMetadataQuery,
} from '../../../api/client'
import { toastError } from '../../Notification'
import { SearchParameters } from './ContentSearchBar'
import FilterInput, { FilterItem } from './FilterInput'

type Props = {
    searchParameters: SearchParameters
    onUpdateSelectedClassifiers: (classifiers: Array<string>) => void
}

export default function GeneralClassifierFilter(props: Props) {
    const { searchParameters, onUpdateSelectedClassifiers } = props
    const [searchTerm, setSearchTerm] = useState('')

    const parameters: SearchParamsForContentOutcome = {
        accountIds: searchParameters.selectedAccountIds,
        teamIds: searchParameters.selectedTeams,
        moderationString: [searchParameters.moderation?.toLowerCase()],
        since: searchParameters.startDateTime,
        before: searchParameters.endDateTime,
        socialMediaServiceIds:
            searchParameters.selectedSocialMediaServices?.map(
                (service) => service.id
            ) || [],
        containsText: searchParameters.keyword,
        classifierSearchParams: searchParameters.selectedClassifiers,
    }

    const { data, loading } = useGetContentClassifierMetadataQuery({
        variables: {
            params: parameters,
        },
        onError: () => {
            toastError(
                'There was a problem getting classification data - try reducing the date range.'
            )
        },
    })

    const contentClassifierMetadataItems =
        (data?.getContentClassifierMetadata ||
            []) as ContentClassifierMetadataView[]

    function handleUpdateSelectedClassifiers(selectedItems: Array<FilterItem>) {
        // @ts-ignore
        onUpdateSelectedClassifiers(selectedItems.map((item) => item.name))
    }

    /**
     * Maps between the Metadata item and the item supported by FilterInput
     */
    function mapClassifierToFilterItem(item: ContentClassifierMetadataView) {
        return {
            id: item.classifierId,
            name: `${item.classifierName}`,
            displayName: `${item.classifierDisplayName} (${item.contentCount})`,
            rank: item.contentCount,
        }
    }

    // Identify all the selected items from the search parameters.
    const selectedItems: FilterItem[] = contentClassifierMetadataItems
        .filter((item) =>
            searchParameters.selectedGeneralClassifiers.includes(
                item.classifierName
            )
        )
        .map(mapClassifierToFilterItem)

    const searchResults: FilterItem[] = contentClassifierMetadataItems
        // Only show classifier metadata that has matching content in the current search
        .filter((item) => item.contentCount > 0)
        // If there is a search term only show matching items.
        .filter((item: ContentClassifierMetadataView) =>
            item.classifierDisplayName
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
        )
        // Map to the object supported by the FilterInput.
        .map(mapClassifierToFilterItem)

    // Sort search results by rank
    searchResults.sort((a, b) => (b.rank || 0) - (a.rank || 0))

    /* 
    This is a complex bit. If the search is across multiple accounts then each account might
    have different a different prompt for a given LLM column, e.g. llm_01 might be threat for 
    account 1 and accusation for account 2. If this is the case then searching on Threat
    will cause accusation items to show up as well. This would be very confusing.
    To work around this we need to disable selection of these items and present the user with a
    message suggesting they change the account selection or talk to customer success.
    
    So the next stage is to identify duplicates in the list of FilterItems and mark them as disabled
    */
    const nameCountMap: { [key: string]: number } = {}

    // Count occurrences of each name in searchResults
    searchResults.forEach((item) => {
        if (item.name) {
            if (nameCountMap[item.name]) {
                nameCountMap[item.name]++
            } else {
                nameCountMap[item.name] = 1
            }
        }
    })

    // Mark items as disabled if they have duplicates
    searchResults.forEach((item) => {
        if (item.name) {
            if (nameCountMap[item.name] > 1) {
                item.disabled = true
                item.message =
                    'Unable to filter on this classifier. Please refine your profile selection or contact customer support.'
            }
        }
    })

    return (
        <FilterInput
            filterTypeName={`Classifiers`}
            searchTerm={searchTerm}
            onSearchTermChange={async (term) => {
                setSearchTerm(term)
            }}
            onUpdateSelectedItems={handleUpdateSelectedClassifiers}
            selectedItems={selectedItems}
            searchResults={searchResults}
            searching={loading}
            hideName={true}
        />
    )
}
