import { Field, Label, Textarea } from '@headlessui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import clsx from 'clsx'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { useTestLlmClassificationPromptLazyQuery } from '../api/client'
import Button from '../components/Button'
import FormFieldError from '../components/errors/FormFieldError'
import { toastError } from '../components/Notification'
import SimpleLoader from '../components/SimpleLoader'
import {
    evaluateMetrics,
    parseMessages,
    PromptResult,
    PromptTestCase,
} from '../util/promptTesting'

const PROMPT_MESSAGE_SUBSTITUTION_VARIABLE_REGEX = /<<<PLACEHOLDER MESSAGE>>>/
const LOCAL_STORAGE_PROMPT_MESSAGES = 'LOCAL_STORAGE_PROMPT_MESSAGES'
const LOCAL_STORAGE_PROMPT = 'LOCAL_STORAGE_PROMPT'

const validationPromptSchema = object({
    prompt: string()
        .required('Please enter a prompt')
        .matches(
            PROMPT_MESSAGE_SUBSTITUTION_VARIABLE_REGEX,
            'Prompt must include the substitution variable <<<PLACEHOLDER MESSAGE>>>'
        ),

    messages: string().required('Please enter a prompt'),
})

export default function PromptTestingPage() {
    const initialMessages =
        localStorage.getItem(LOCAL_STORAGE_PROMPT_MESSAGES) || ''
    const initialPrompt = localStorage.getItem(LOCAL_STORAGE_PROMPT) || ''
    const [promptResults, setPromptResults] = useState<PromptResult[]>([])
    const [parsedMessages, setParsedMessages] = useState<PromptTestCase[]>([])
    const [testingPromptsLoading, setTestingPromptsLoading] = useState(false)

    const [testLlmClassificationPrompt] =
        useTestLlmClassificationPromptLazyQuery({
            onError: (error) => {
                toastError(
                    `Failed to test LLM classification prompt: ${error.message}`
                )
            },
        })

    const handleOnValidate = async () => {
        const messages = promptForm.getValues().messages
        // This is where we define form validation for the test message.
        if (!messages || messages === '') {
            promptForm.setError('messages', {
                message: 'Please enter messages for testing. See advice.',
            })
        } else {
            const parsedMessages = parseMessages(messages)
            if (parsedMessages) {
                setParsedMessages(parsedMessages)
            }
        }
    }

    const handleOnTest = async () => {
        const prompt = promptForm.getValues().prompt
        const messages = promptForm.getValues().messages
        // This is where we define form validation for the test message.
        if (!messages || messages === '') {
            promptForm.setError('messages', {
                message: 'Please enter messages for testing. See advice.',
            })
        } else {
            const results: PromptResult[] = []

            setPromptResults([])
            if (parsedMessages) {
                setTestingPromptsLoading(true)
                for (const message of parsedMessages) {
                    try {
                        const { data } = await testLlmClassificationPrompt({
                            variables: {
                                prompt,
                                testMessage: message.text,
                            },
                        })
                        const promptResult: PromptResult = {
                            text: message.text,
                            target: message.target,
                            result: data?.testLlmClassificationPrompt
                                ? true
                                : false,
                        }

                        if (data) {
                            results.push(promptResult)
                        }
                    } catch (error) {
                        console.error(
                            'Error testing LLM classification prompt:',
                            error
                        )
                    }
                }
                setTestingPromptsLoading(false)
            }

            setPromptResults(results)
        }
    }

    const promptForm = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: yupResolver(validationPromptSchema),
        defaultValues: {
            prompt: initialPrompt,
            messages: initialMessages,
        },
    })

    const { accuracy, precision, recall } = evaluateMetrics(promptResults)

    const messages = promptForm.getValues().messages
    const prompt = promptForm.getValues().prompt

    // Save to localStorage
    localStorage.setItem(LOCAL_STORAGE_PROMPT_MESSAGES, messages)
    localStorage.setItem(LOCAL_STORAGE_PROMPT, prompt)

    return (
        <div>
            <div className="m-10 prose prose-stone">
                <h2 className="text-gray-950 initial">Prompt testing page</h2>

                <div>
                    <FormProvider {...promptForm}>
                        <form>
                            <Field className="mt-6">
                                <Label className="block font-medium leading-6 text-gray-90">
                                    Prompt
                                </Label>

                                <Textarea
                                    {...promptForm.register('prompt')}
                                    className={clsx(
                                        'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm',
                                        'ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 ',
                                        'focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6'
                                    )}
                                    rows={20}
                                />
                                {promptForm.formState.errors.prompt && (
                                    <FormFieldError
                                        message={
                                            promptForm.formState.errors.prompt
                                                .message || ''
                                        }
                                    />
                                )}
                            </Field>
                            <Field className="mt-10">
                                <Label className="block font-medium leading-6 text-gray-90">
                                    Test script
                                </Label>

                                <Textarea
                                    {...promptForm.register('messages', {})}
                                    className={clsx(
                                        'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm',
                                        'ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 ',
                                        'focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6'
                                    )}
                                    rows={20}
                                />
                                {promptForm.formState.errors.messages && (
                                    <FormFieldError
                                        message={
                                            promptForm.formState.errors.messages
                                                .message || ''
                                        }
                                    />
                                )}
                            </Field>
                            <div className="prose-p text-sm">
                                <p>
                                    The test script needs to have a test message
                                    per line. The message should have a trailing
                                    comma and the expected result after that as
                                    either "Yes" or "No". The following are all
                                    valid:
                                </p>
                                <ul>
                                    <li>You are right this is super, Yes</li>
                                    <li>"You're correct", "Yes"</li>
                                </ul>
                                <p>
                                    If you change the test data you must click
                                    "Validate" before running the test again.
                                    Review the validated test data in the "Test
                                    Data section below"
                                </p>
                            </div>

                            <div className="flex flex-row gap-4">
                                <div className="mt-2">
                                    <Button
                                        primary={true}
                                        text="Validate"
                                        onClick={handleOnValidate}
                                    ></Button>
                                </div>
                                <div className="mt-2">
                                    <Button
                                        primary={true}
                                        text="Test"
                                        onClick={handleOnTest}
                                        disabled={
                                            !parsedMessages ||
                                            parsedMessages.length === 0
                                        }
                                    ></Button>
                                </div>
                            </div>

                            {testingPromptsLoading && (
                                <SimpleLoader loading={true} />
                            )}

                            <div>
                                <h2>Results</h2>
                                {promptResults && promptResults.length > 0 && (
                                    <>
                                        <p>
                                            See{' '}
                                            <a
                                                href="https://www.notion.so/Arwen-Product-Playbook-1274f06b4bd180a09466e4fb051a8aaa?pvs=4#1284f06b4bd180b4a525e191ed0f66ca"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                here
                                            </a>{' '}
                                            for more information on accuracy,
                                            precision and recall.
                                        </p>
                                        <table className="table-auto">
                                            <thead>
                                                <tr>
                                                    <th>Metric</th>
                                                    <th>Result</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Accuracy</td>
                                                    <td>{accuracy}%</td>
                                                    <td className="text-gray-600">
                                                        Out of everything I
                                                        checked, how often did I
                                                        get it right?
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Precision</td>
                                                    <td>{precision}%</td>
                                                    <td className="text-gray-600">
                                                        Of all the things I
                                                        called positive, how
                                                        many were really
                                                        positive?
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Recall</td>
                                                    <td>{recall}%</td>
                                                    <td className="text-gray-600">
                                                        Of all the actual
                                                        positive things, how
                                                        many did I find?
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </>
                                )}
                                <table className="table-auto">
                                    <thead>
                                        <tr>
                                            <th>Text</th>
                                            <th>Target</th>
                                            <th>Response</th>
                                            <th>Result</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {promptResults &&
                                            promptResults.map((message) => {
                                                return (
                                                    <tr
                                                        key={message.text}
                                                        className=""
                                                    >
                                                        <td>{message.text}</td>
                                                        <td>
                                                            {message.target}{' '}
                                                        </td>
                                                        <td>
                                                            {message.result
                                                                ? 'Yes'
                                                                : 'No'}
                                                        </td>
                                                        <td>
                                                            {' '}
                                                            {message.target ===
                                                                'Yes' &&
                                                                message.result && (
                                                                    <div className="font-bold text-green-600">
                                                                        SUCCESS
                                                                    </div>
                                                                )}
                                                            {message.target ===
                                                                'Yes' &&
                                                                !message.result && (
                                                                    <div className="font-bold text-red-600">
                                                                        FAIL -
                                                                        false
                                                                        negative
                                                                    </div>
                                                                )}
                                                            {message.target ===
                                                                'No' &&
                                                                !message.result && (
                                                                    <div className="font-bold text-green-600">
                                                                        SUCCESS
                                                                    </div>
                                                                )}
                                                            {message.target ===
                                                                'No' &&
                                                                message.result && (
                                                                    <div className="font-bold text-red-600">
                                                                        FAIL -
                                                                        false
                                                                        positive
                                                                    </div>
                                                                )}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <h2>Test data</h2>
                                <table className="table-auto">
                                    <thead>
                                        <tr>
                                            <th>Text</th>
                                            <th>Target</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {parsedMessages &&
                                            parsedMessages.map((message) => {
                                                return (
                                                    <tr
                                                        key={message.text}
                                                        className=""
                                                    >
                                                        <td>{message.text}</td>
                                                        <td>
                                                            {message.target}{' '}
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    )
}
